import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "iOSDC Japan 2017 にメドレーが協賛しました",
  "date": "2017-09-27T03:00:00.000Z",
  "slug": "entry/2017/09/27/120000",
  "tags": ["medley"],
  "hero": "./2017_09_27.png",
  "heroAlt": "iosdc"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`みなさん、こんにちは。開発本部のエンジニア・平木です。`}</p>
    <p>{`表題の通りなんですが、メドレーは今年からテックカンファレンスの協賛を本格的に開始しています。その内の 1 つが 9/15〜17 に早稲田大学で開かれた `}<a parentName="p" {...{
        "href": "https://iosdc.jp/2017/"
      }}>{`iOSDC Japan 2017`}</a>{`(以下 iOSDC)です。`}</p>
    <p>{`みなさんご存知かと思いますが、iOSDC は国内の iOS イベントの中では try! Swift と並ぶ最大級のイベントです。`}</p>
    <p>{`メドレーは今回、ゴールドスポンサーとして協力させていただきました。ブース出展はしていないのですが、CLINICS の iOS 版をメインで担当している高井と一緒に参加してきました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170925/20170925173341.png",
      "alt": "20170925173341.png"
    }}></img>
    <h1>{`会場の雰囲気`}</h1>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170927/20170927113807.png",
      "alt": "20170927113807.png"
    }}></img>
    <p>{`まず第一印象として人が多かったです。`}</p>
    <p>{`会場は 4 つに分かれていたのですが、一番大きいトラック A 以外はセッションが始まるころになると満席になっていることが多く、自分が見たセッションでは大体立ち見が出ている感じでした。`}</p>
    <p>{`企業ブースを出展している企業さんの中では、サイバーエージェントさんのブースが iOS 開発に関するアンケートを取っていたようで、盛況でした。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170925/20170925174226.png",
      "alt": "20170925174226.png"
    }}></img>
    <p>{`スタッフさんの人数も多かったということもあるのか、スケジュール通りにセッションが進んでいるのが印象的でした。機材トラブルなどもほとんど 見受けられなかったので、運営がとてもスムーズでした。`}</p>
    <p>{`また、朝にオープニングムービーが流れてスポンサーの紹介などをしている映像が流れるのですが、まさかの三石琴乃さんがナレーションでビビりました。 すげえ。`}</p>
    <p><undefined parentName="p">{`
		`}<video {...{
          "src": "https://youtu.be/AC7C5CY1Meo?t=4m55s",
          "width": 800,
          "height": "auto",
          "preload": "auto",
          "muted": "true",
          "title": "https://youtu.be/AC7C5CY1Meo?t=4m55s",
          "playsInline": true,
          "controls": true
        }}></video>{`
	`}</undefined></p>
    <p>{`iOSDC ではランチが配られる形式なのですが、来場者が多かったはずなのに、食べる場所がない…みたいなこともなく良かったですね。`}</p>
    <p>{`ランチはサンドイッチで、大変おいしかったです。席が近いからか知らない人とも話しやすい雰囲気を感じました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170927/20170927113648.png",
      "alt": "20170927113648.png"
    }}></img>
    <h1>{`セッション`}</h1>
    <p>{`一口に iOS 関連といってもセッションはかなりバラエティに富んだ内容です。Swift に関するもの、設計に関するもの、運用に関わるものなどなど…。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170925/20170925174414.png",
      "alt": "20170925174414.png"
    }}></img>
    <p>{`ちょいちょいと Android や Kotlin と関係したセッションがあったのは意外でした。が、考えてみると両方をやってるエンジニアの方も多いでしょうし、 Swift と Kotlin も文法なんかは割と似てるということで、親和性があるんでしょうね。`}</p>
    <p>{`全体としては、やはり、iPhone X や iOS11 の話題がちらほらと出ており、さすが専門のカンファレンスだけあってわりとつっこんだ話が聞けたのが良かったです。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170927/20170927113424.png",
      "alt": "20170927113424.png"
    }}></img>
    <p>{`個人的には言語の話なども面白かったんですが、運用周りの話をしてるセッションがとても面白かったです。`}</p>
    <p>{`リニューアル周りの話題や CI での運用の話などは大変に参考になりましたし、特にトレタさんの `}<a parentName="p" {...{
        "href": "https://iosdc.jp/2017/node/1422"
      }}>{`ロギング`}</a>{`の話はぜひ弊社でも実践せねばという話でした。`}</p>
    <p>{`LT の時間前にビールが配られて、飲みながら参加できるのは大変ポイントが高いですね!`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170927/20170927113925.png",
      "alt": "20170927113925.png"
    }}></img>
    <h1>{`最後に`}</h1>
    <p>{`iOSDC は自分は初めての参加だったのですが、大変よいイベントだなと感じました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170925/20170925180142.png",
      "alt": "20170925180142.png"
    }}></img>
    <p>{`iOS だと特に大体毎年新しいバージョンや新しい機種が出てきますし、 そうなると新しい SDK や API もどんどん出てくるという感じです。もちろんネットの情報などで知識をアップデートしたりするのも必要ですが、こういったイベントでより新鮮な知見などを共有できるというのは、やはり素晴しいなあという感想です。`}</p>
    <p>{`弊社のアプリ開発でもそういった知見などを活かして開発していける仲間を引き続き募集しています!
興味がある方は、こちらの「話を聞いてみたい」からご連絡ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley"
      }}>{`https://www.wantedly.com/companies/medley`}</a></p>
    <p>{`開発本部の雰囲気を知りたい方はこちらからどうぞ。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      